<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <select-component
                        name="enterprise_id"
                        lable="申请企业"
                        :farr="all_enterprise"
                        :fvalue_name="details.enterprise_name"
                        @clk="confirm_enterprise"
                    ></select-component>
                    <select-component
                        name="department_id"
                        lable="申请部门"
                        :farr="all_department"
                        :fvalue_name="details.department_name"
                        @clk="confirm_department"
                    ></select-component>
                    <select-component
                        name="type"
                        lable="申请类型"
                        :farr="all_type"
                        :fvalue_name="details.type | type_name"
                        @clk="confirm_type"
                    ></select-component>
                    <van-field
                        v-model="details.title"
                        name="title"
                        label="借支标题"
                        placeholder="借支标题"
                        :rules="[{ required: true, message: '请填写借支标题' }]"
                        :border="false"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.content"
                        name="content"
                        label="申请原因"
                        placeholder="申请原因"
                        :rules="[{ required: true, message: '请填写申请原因' }]"
                        :border="false"
                    />
                    <number-keyboard-component
                        name="money"
                        label="申请金额"
                        extra_key="."
                        right_icon="元"
                        placeholder="请输入金额"
                        :fvalue="details.money"
                        @on_input="confirm_money"
                    ></number-keyboard-component>
                    <select-component
                        name="pay_way"
                        lable="汇款方式"
                        :farr="all_pay_way"
                        :fvalue_name="details.pay_way | pay_way_name"
                        @clk="confirm_pay_way"
                    ></select-component>
                    <select-component
                        name="with_bank_card"
                        lable="选择银行卡"
                        :farr="all_bank_card"
                        :fvalue_name="details.account_card"
                        @clk="confirm_bank_card"
                    ></select-component>
                    <van-cell title="开户行" :value="details.account_bank" :border="false"/>
                    <van-cell title="收款人" :value="details.account_name" :border="false"/>
                    <van-cell title="上传附件">
                        <template #label>
                            <uploader-component
                                :farr="details.other"
                                url="petty/upload"
                                @update_img="update_img"
                            ></uploader-component>
                        </template>
                    </van-cell>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="备用金单号" :value="details.name" />
                <van-cell title="状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell title="申请企业" :value="details.enterprise_name" />
                <van-cell title="申请部门" :value="details.department_name" />
                <van-cell title="申请人" :value="details.staff_name" />
                <van-cell title="借支标题" :value="details.title" />
                <van-cell title="申请原因" :value="details.content" />
                <van-cell title="申请类型">
                    <template #right-icon>
                        <van-tag plain type="primary" v-if="details.type===0">提前借支</van-tag>
                        <van-tag plain type="warning" v-if="details.type===1">储备额度</van-tag>
                    </template>
                </van-cell>
                <van-cell title="申请金额" :value="details.money | diy_money" />
                <van-cell title="已用金额">
                    <template #right-icon>
                        <span style="color: #FF0000">{{details.use_money | diy_money}}</span>
                    </template>
                </van-cell>
                <van-cell title="剩余金额">
                    <template #right-icon>
                        <span style="color: #2828FF">{{(details.money - details.use_money) | diy_money}}</span>
                    </template>
                </van-cell>
                <van-cell title="汇款方式" :value="details.pay_way | pay_way_name" />
                <van-cell title="开户行" :value="details.account_bank" />
                <van-cell title="银行卡号" :value="details.account_card" />
                <van-cell title="收款人" :value="details.account_name" />
                <van-cell title="附件">
                    <template #label>
                        <uploader-component
                            :farr="details.other"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="withdraw_auth" round block type="danger" @click="pettyWithdraw()">审批撤回</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="edit_auth" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="del_auth" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { 
    petty_details_request,
    petty_del_request,
    petty_edit_request,
    petty_add_request,
    petty_to_approval_request,
    petty_withdraw_request
    } from '@/network/finance/Petty.js'
import { enterprise_list_request,department_list_request,bank_card_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'PettyDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                enterprise_id: this.$store.state.user.enterprise_id,
                enterprise_name: this.$store.state.user.enterprise_name,
                department_id: this.$store.state.user.department_id,
                department_name: this.$store.state.user.department_name,
                type: '',
                title: '',
                content: '',
                money: '',
                pay_way: '',
                with_bank_card: '',
                account_card: '',
                account_name: '',
                account_bank: '',
                other: []
            },
            edit: false,
            all_enterprise: [],
            all_department: [],
            all_type: [{id: 0, name: '提前借支'},{id: 1, name: '储备额度'}],
            all_pay_way: [{id: 0, name: '公对公'},{id: 1, name: '公对私'},{id: 2, name: '私对私'},{id: 3, name: '私对公'}],
            all_bank_card: [],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改备用金' : '新增备用金') : '备用金详情'
        }
    },
    methods:{
        get_petty_details() {
            this.$store.commit('true_loading')
            petty_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                petty_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        back_details() {
            this.get_petty_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                department_id: this.details.department_id,
                type: this.details.type,
                title: this.details.title,
                content: this.details.content,
                money: this.details.money,
                pay_way: this.details.pay_way,
                with_bank_card: this.details.with_bank_card,
                account_card: this.details.account_card,
                account_name: this.details.account_name,
                account_bank: this.details.account_bank,
                other: this.details.other
            }
            petty_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_petty_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                department_id: this.details.department_id,
                type: this.details.type,
                title: this.details.title,
                content: this.details.content,
                money: this.details.money,
                pay_way: this.details.pay_way,
                with_bank_card: this.details.with_bank_card,
                account_card: this.details.account_card,
                account_name: this.details.account_name,
                account_bank: this.details.account_bank,
                other: this.details.other
            }
            petty_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_petty_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 2,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                petty_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_petty_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        pettyWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                petty_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_petty_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_card_list() {
            bank_card_list_request()
                .then((s) => {
                    this.all_bank_card = s.result.map( v => {
                        v.name = '【'+v.user_name+'】'+v.card
                        return v
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        confirm_enterprise(e) {
            this.details.enterprise_id = e.id
            this.details.department_id = ''
            department_list_request({enterprise_id: e.id})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_department = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_department(e) {
            this.details.department_id = e.id
        },
        confirm_type(e) {
            this.details.type = e.id
        },
        confirm_money(e) {
            this.details.money = e
        },
        confirm_pay_way(e) {
            this.details.pay_way = e.id
        },
        confirm_bank_card(e) {
            this.details.with_bank_card = e.id
            this.details.account_card = e.card
            this.details.account_bank = e.bank
            this.details.account_name = e.user_name
        },
        update_img(arr) {
            this.details.other = arr
        },
        is_auth() {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false
            
            if(this.details.state === 0) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交备用金申请')
                    this.edit_auth = this.$_has('修改备用金申请')
                    this.del_auth = this.$_has('删除备用金申请')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回备用金申请')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批备用金申请')
                }
                this.sel_approval_auth = this.$_has('查看备用金申请审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看备用金申请审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交备用金申请')
                    this.edit_auth = this.$_has('修改备用金申请')
                    this.del_auth = this.$_has('删除备用金申请')
                }
                this.sel_approval_auth = this.$_has('查看备用金申请审批记录')
            }
        },
    },
    filters:{
        diy_money(v) {
            return v ? '￥ '+thousandBitSeparator(fomatFloat(v)) : '￥ 0.00'
        },
        type_name(v) {
            return v===0 ? '提前借支' : (v===1 ? '储备额度' : '')
        },
        pay_way_name(v) {
            if(v===0) {
                return '公对公'
            } else if(v===1) {
                return '公对私'
            } else if(v===2) {
                return '私对私'
            } else if(v===3) {
                return '私对公'
            }
        }
    },
    props:{},
    created(){
        this.id = this.$route.query.id
        if(this.id) {
            this.get_petty_details()
        }
        else
        {
            this.edit = true
        }

        this.get_all_enterprise()
        this.get_card_list()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>